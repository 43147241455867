<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      label-cols-md="4"
      :label="label"
      :description="desc"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <b-row>

          <b-col cols="5">
            <b-input-group>
              <flat-pickr
                v-model="start"
                :config="config"
              />
              <b-input-group-append is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <span>s/d</span>
          <b-col cols="5">
            <b-input-group>
              <flat-pickr
                v-model="end"
                :config="config"
              />
              <b-input-group-append is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormGroup,BInputGroup,BInputGroupAppend,BCol,BRow} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

  export default {
    components: {
      BFormGroup,
      ValidationProvider,
      flatPickr,
      BInputGroup,
      BInputGroupAppend,
      BCol,
      BRow,
    },
    props: {
      start: {
        type: String,
        default:''
      },
      end: {
        type: String,
        default:''
      },
      label: {
        type: String,
        default: 'LabelName'
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        selected: this.value,
        config:{
          static: true,
          altInput: true,
          plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "M Y"})]
        }

      }
    },
    methods:{
      onSelected() {
      this.$emit('input', this.selected)
    },
  }
    
    
  }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>