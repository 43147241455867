<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      label-cols-md="4"
      :label="label"
      :description="desc"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <base-select
          :id="label"
          :value="value"
          :dataurl="dataurl"
          :title="label"
          @input="handleInput"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import BaseSelect from '@/views/base/BaseSelect.vue'

import { ValidationProvider } from 'vee-validate'
  export default {
    components: {
      BFormGroup,
      ValidationProvider,
      BaseSelect
    },
    props: {
      value: {
        type: Object,
        default: ()=>{}
      },
      label: {
        type: String,
        default: 'LabelName'
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
      dataurl: {
        type: String,
        default: ''
      },
    },
    methods: {
      handleInput(e) {
        this.$emit('input', e)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>